import type { WorkingUnitsNumber } from "../../common";

export class PaymentConfigRefUsageDataJSON {
  flatFee?: {
    amount: WorkingUnitsNumber;
  };
  ecommerceData?: {
    treeCount: number;
    treePrice: number;
  };
}
