import type { ProductPricingData } from "./impact-product-pricing-data.type";

export class ProductAndQuantityEntryJSON {
  baseProductName: string;
  quantity: number;
}

export class PlansConfigJSON {
  // used to identify the plan programmatically, as well as
  // to select the correct human-readable titles /
  // descriptions in user interfaces
  identifier: string;

  products: ProductAndQuantityEntryJSON[];

  stripeProductId: string;
}

export class PresetProductConfigJSON {
  plans: PlansConfigJSON[];
}

export class OptionsConfigJSON {
  identifier: string;

  baseProductName: string;

  // The increment to use when the user clicks a "+" or "-" button
  defaultIncrement: number;
}

export class CustomProductConfigJSON {
  options: OptionsConfigJSON[];
}

export type OptionsConfigWithPrice = OptionsConfigJSON & {
  price: number;
};

export type PlansConfigWithPrice = PlansConfigJSON & {
  price: number;
};

export type CustomProductConfigWithPrices = {
  options: OptionsConfigWithPrice[];
};

export type PresetProductConfigWithPrices = {
  plans: PlansConfigWithPrice[];
};

export class UsageProductConfigJSON {
  usage: {
    source: "ecommerce_integration";
    productsPerUnit: ProductAndQuantityEntryJSON[];
    flatFee?: { stripeProductId: string; pricing: ProductPricingData };
  };
}

export class OfferProductConfigJSON {}

export type ProductConfigJSON =
  | PresetProductConfigJSON
  | CustomProductConfigJSON
  | UsageProductConfigJSON
  | OfferProductConfigJSON;
